/*curso detalle*/

.lms-item .course_hero figcaption img {
    max-width: initial !important;
    max-height: initial !important;
}

.lms-item h1,
.lms-item h2.price {
    font-weight: 600;
    color: #35373B;
    font-size: 3rem;
    letter-spacing: 0.016rem;
}

.lms-item h3 {
    font-size: 1.25rem;
    font-weight: 600;
    letter-spacing: 0.016rem;
    color: #35373B;
}

.lms-item h4 {
    font-size: 1.25rem;
    font-weight: 600;
    letter-spacing: 0.016rem;
    color: #35373B;
}

.lms-item .caption {
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 600;
    letter-spacing: 0.078rem;
    color: #7F838C;
}

.lms-item p.lead {
    font-size: 1.25rem;
    font-weight: 600;
    letter-spacing: 0.016rem;
    opacity: .6;
}

.lms-item h6 {
    font-size: 0.875rem;
    font-weight: 600;
}

.lms-item p {
    font-size: 1rem;
    letter-spacing: 0.031rem;
    line-height: 1.625rem;
    color: #5A5E66;
}

.lms-item .course-value {
    display: inline-flex!important;
}

.lms-item .course-value button {
    width: 100%;
}

.lms-item .course-value .bordered {
    border-top: 1px solid #A1A7B3;
    border-bottom: 1px solid #A1A7B3;
    display: inline-flex!important;
    align-items: center;
}

.lms-item .course-value .bordered p {
    border-right: 1px solid #A1A7B3;
    padding-right: 1rem;
}

.lms-item .course-value p {
    position: relative;
}

.lms-item .course-value p::after {
    content: '';
    right: 0.5rem;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: #a1a7b333;
}

.lms-item .course-value small {
    display: block;
}

.lms-item .course_hero .container {
    padding-top: 5rem;
}

.lms-item .course_hero .course_img img {
    border: 2px solid #707070;
    border-radius: 10px;
    background-color: #fff;
    z-index: 2;
    position: relative;
}

.lms-item .course_hero .course_img {
    position: relative;
}

.lms-item .course_hero .course_img:after {
    content: '';
    border: 2px solid #707070;
    border-radius: 10px;
    top: -28px;
    left: -28px;
    position: absolute;
    z-index: 0;
    display: block;
    width: 100%;
    height: 100%;
}


/*acordeon*/

.lms-item .accordion-item {
    border-left: 0;
    border-right: 0;
    border-color: #6e6e6e;
}

.lms-item .accordion-item h4 {
    color: #35373B;
    font-size: .875rem;
    letter-spacing: 0.029rem;
}

.lms-item .accordion-item p {
    font-size: .875rem;
    letter-spacing: 0.015rem;
    line-height: 1.625rem;
    color: #5A5E66;
}

.lms-item .accordion-button:not(.collapsed) {
    color: #494c51;
    box-shadow: none;
    border-bottom: #a3a3a3;
    background-color: #fff;
}

.lms-item .certificacion_accordion .accordion-button {
    font-weight: 400;
    color: #35373b;
}

.certificacion svg {
    fill: #5A5E66!important;
}

.lms-item #que-incluye-acordeon .accordion-header {
    border: 2px solid #A1A7B333;
    border-radius: 8px;
    cursor: pointer;
    transition: .25s all ease-in-out;
}

.lms-item #que-incluye-acordeon .accordion-header:hover {
    box-shadow: 0px 3px 6px #00000029;
}

.lms-item #que-incluye-acordeon .accordion-button {
    padding: 1rem;
    border-radius: 8px;
}

.lms-item #que-incluye-acordeon .accordion-body {
    border: 2px solid #A1A7B333;
    border-radius: 8px;
}

.lms-item .certificacion_accordion .accordion-button,
.lms-item .faqs_accordion .accordion-button {
    padding: 0.5rem 0;
    font-size: 0.875rem;
    font-weight: 600;
}

.certificacion_accordion .accordion-body,
.lms-item .faqs_accordion .accordion-body {
    padding: 0;
}

.lms-item .certificacion_accordion .accordion-button::after,
.lms-item .faqs_accordion .accordion-button::after {
    background-size: 1rem;
}

.lms-item .certificacion_accordion .accordion-item,
.lms-item .faqs_accordion .accordion-item {
    border-bottom: 1px solid #35373b33!important;
}

.lms-item .accordion-button:not(.collapsed)::after {
    background-image: url("../../../public/img/flechas_acordeon/arriba.svg");
}

.lms-item .accordion-button::after {
    width: 1rem;
    height: 1rem;
    background-size: 1rem;
    border-radius: 100%;
    background-position: center;
    background-image: url("../../../public/img/flechas_acordeon/arriba.svg");
}

.lms-item .accordion-button:focus {
    border: none !important;
    box-shadow: none !important;
}

.lms-item .profile-acercaVos .row {
    border: none !important;
}

.lms-item .que-incluye-list i {
    background-color: #a1a7b375;
    color: #fff;
    font-size: 0.675rem;
    width: 1.25rem;
    height: 1.25rem;
    display: inline-flex;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
}

.lms-item .que-incluye-list a {
    text-decoration: none;
}


/*que vas a aprender*/

.lms-item .carousel-control-next,
.lms-item .carousel-control-prev {
    top: initial;
    bottom: -15%;
    width: 15%;
    color: #000;
    border-radius: 100%;
    height: 2rem;
    width: 2rem;
    line-height: 2rem;
    background: #d6dbe6;
}

.lms-item .carousel-control-prev {
    left: calc(50% - 70px);
}

.lms-item .carousel-control-next {
    right: calc(50% - 70px);
}

.lms-item .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.lms-item .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.lms-item .carousel-control-next-icon,
.lms-item .carousel-control-prev-icon {
    width: 1rem;
    height: 1rem;
}


/*que incluye*/

.lms-item .accordion-item {
    border: none;
}

.lms-item .accordion-header,
.lms-item .accordion-body {
    border: none;
    border-radius: 0.5rem;
}

.lms-item .accordion-header h4 {
    font-size: 0.875rem;
    text-transform: uppercase;
    letter-spacing: 0.063rem;
    color: #35373B;
}

.lms-item .accordion-header p {
    font-size: 1rem;
    font-weight: normal;
    color: #5A5E66;
    padding-right: 1rem;
}

.lms-item .accordion-collapse {
    border-radius: 0.5rem;
}


/*faqs*/

@media (max-width: 700px) {
    .lms-item .course_title {
        margin-bottom: 1em;
    }
    .lms-item .courses_hero .filter-nav li h4 {
        font-size: 1em;
        line-height: 20px;
    }
    .lms-item .p-5 {
        padding: 2rem !important;
    }
    .lms-item .mb-5 {
        margin-bottom: 2rem !important;
    }
    .lms-item .mt-5 {
        margin-top: 2rem !important;
    }
    .lms-item .mx-5 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }
    .lms-item .courses_hero .filter-nav li p {
        font-size: 14px;
        line-height: 20px;
    }
    .lms-item .courses_hero .btn {
        border: 1px solid black;
        width: 100%;
        border-radius: 40px;
        text-align: left;
        padding: 13px 25px;
    }
    .lms-item .courses_hero h3 {
        font-weight: normal;
    }
    .lms-item .que_incluye_el_programa a.btn {
        display: block !important;
        width: 100%;
    }
    .lms-item .course_hero figure figcaption h4 {
        width: auto;
        margin: 0 !important;
        font-size: 1em !important;
        padding: 0 !important;
    }
    .lms-item .course_hero h4 em img {
        flex: 1;
        object-fit: cover;
        max-height: 20px;
        object-position: center;
    }
}


/*cuando-puedo-comenzar*/

.lms-item .cuando-puedo-comenzar article {
    gap: 10px;
    margin-bottom: 20px;
}

.lms-item .cuando-puedo-comenzar aside {
    width: 55px;
    min-height: 55px;
    background-color: #A1A7B31A;
    border-radius: 4px;
    display: flex;
    align-items: center;
    color: #35373B;
    justify-content: center;
    overflow: hidden;
}

.lms-item .cuando-puedo-comenzar h5 {
    font-size: .875rem;
    color: #35373B;
    text-transform: none;
    letter-spacing: 0.029rem;
}

.lms-item .semanal-info {
    height: 55px;
}

.lms-item .semanal-info img {
    width: 36px;
    height: 36px;
}

.lms-item .cuando-puedo-comenzar aside h6 {
    font-size: 2.125rem;
    font-weight: 500;
    margin: 0;
}

.lms-item .cuando-puedo-comenzar aside.dia-icon {
    align-items: flex-start;
    padding-top: 1em;
}


/*entrenadores_expertos*/

.lms-item .entrenadores_expertos figure img {
    border-radius: 0.5rem;
    object-fit: cover !important;
    width: 100%;
}

.lms-item .entrenadores_expertos figure a {
    bottom: 1rem;
    right: 1rem;
    background-color: #fff;
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #494c51;
    font-size: 0.875rem;
    border-radius: 100%;
    text-decoration: none;
}

.lms-item .entrenadores_expertos .carousel-control-prev,
.lms-item .entrenadores_expertos .carousel-control-next {
    top: initial;
    bottom: initial;
    left: initial;
    right: initial;
    position: relative;
    height: 30px;
    width: 30px;
    line-height: 30px;
}

.lms-item .entrenadores_expertos .carousel-control-next-icon,
.lms-item .entrenadores_expertos .carousel-control-prev-icon {
    width: 1rem;
    height: 1rem;
}

#entrenadores_carrusel .carousel-item .row {
    box-shadow: 0px 3px 6px #00000029;
}

.specs svg {
    width: 3.75rem;
    height: 3.75rem;
}

.specs article {
    height: 174px;
}

.que_vas_a_aprender li {
    font-size: .875rem;
    font-weight: 600;
    letter-spacing: 0.029rem;
}

.cuando-puedo-comenzar .shadow-1 {
    box-shadow: 0px 3px 6px #00000033;
    border-radius: 4px;
    background-color: #ffffff;
}

.cuando-puedo-comenzar .imagen {
    left: 0;
    z-index: -1;
    top: auto;
    bottom: auto;
    margin: auto;
    position: absolute;
}

@media (max-width: 700px) {
    .cuando-puedo-comenzar .imagen {
        position: relative;
    }
    .lms-item h1,
    .lms-item h2.price {
        font-size: 1.25rem;
    }
    .lms-item p.lead {
        font-size: 1rem;
    }
    .lms-item h2 {
        font-size: 1.125rem;
    }
    .lms-item .course_hero figcaption img {
        width: 40px;
    }
    .lms-item .course_hero .row {
        flex-direction: column-reverse;
        padding-top: 2rem;
    }
    .lms-item .specs .col {
        flex: 50%;
        padding-bottom: 2rem;
    }
    .lms-item .que_vas_a_aprender .row {
        gap: 2rem;
    }
    .lms-item #que-incluye-acordeon article {
        padding-right: 1rem !important;
    }
    .lms-item .entrenadores_expertos .col-md-1 {
        position: absolute;
        width: 25%;
    }
    .lms-item .entrenadores_expertos .col-md-1:first-child {
        left: -1rem;
    }
    .lms-item .entrenadores_expertos .col-md-1:last-child {
        right: -1rem;
        justify-content: flex-end;
        display: flex;
    }
    .lms-item .entrenadores_expertos .carousel-item .row {
        gap: 1rem;
    }
    .lms-item .entrenadores_expertos .carousel-item article {
        padding-left: 0 !important;
    }
    .lms-item .course_hero .container {
        padding-top: 2rem;
    }
    .certificacion {
        margin-bottom: 2rem;
    }
    .lms-item .accordion-header p {
        padding-left: 1rem;
        padding-right: 0;
        white-space: nowrap;
    }
    .certificacion_accordion {
        margin-bottom: 1rem;
    }
    .cuando-puedo-comenzar .shadow-1 {
        margin-bottom: 2rem;
    }
}