.courses_list .course_thumb {
  box-shadow: 0px 1px 3px #00000033;
  border-radius: 4px;
  overflow: hidden;
}

.course_thumb h3 {
  font-size: 1.25rem;
  letter-spacing: 0.016rem;
  color: #35373b;
  font-weight: 600;
}

.course_thumb figcaption p {
  font-size: 0.875rem;
  color: #5a5e66;
  letter-spacing: 0.015rem;
  line-height: 1.125rem;
}

.blue_pill {
  background-color: #134ed633;
  color: #134ed6;
  font-size: 0.625rem;
  letter-spacing: 0.078rem;
  font-weight: 600;
  border-radius: 0.25rem;
}

/*toolbar*/

.toolbar h6 {
  color: #5a5e66;
  font-size: 0.625rem;
  text-transform: uppercase;
  letter-spacing: 0.078rem;
  font-weight: 600;
}

.grey_pill {
  background-color: #5a5e6633;
  color: #5a5e66;
  font-size: 0.625rem;
  letter-spacing: 0.078rem;
  font-weight: 600;
  border-radius: 0.25rem;
}

.modeIsActive {
  /* background-color: #373a3f33;
  color: #212529; */
  background-color: #ff4d59 !important;
  color: #ffffff;
}

.modeIsActive:hover {
  color: #ffffff;
}

.categorias_selector {
  position: relative;
}

.categorias_selector button {
  background-color: #fff;
  border: 1px solid #7f838c;
  height: 2.313rem;
  border-radius: 2.313rem;
  padding: 1.5rem;
  color: #5a5e66;
  font-size: 0.625rem;
  letter-spacing: 0.078rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  justify-content: space-between;
  width: 100%;
}

.categorias_selector button:hover {
  background-color: #f5f9fd;
}

.categorias_selector .filter-categorías {
  border-radius: 0.625rem;
  background-color: #ffffff;
  z-index: 1;
  padding: 0.5rem;
  color: #5a5e66;
  font-size: 0.625rem;
  letter-spacing: 0.078rem;
  font-weight: 600;
  width: 100%;
  box-shadow: 0px 3px 6px #00000029;
}

.categorias_selector .filter-categorías li p {
  font-weight: 300;
  font-size: 0.625rem;
  color: #5a5e66;
  letter-spacing: 0.014rem;
  margin: 0;
}

.categorias_selector .filter-categorías li h4 {
  color: #5a5e66;
  font-size: 0.625rem;
  letter-spacing: 0.078rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
