.purchase .separator {
  background-color: #b8bfcc;
  border: none;
  margin: 0.5rem 0 !important;
}

.purchase .payPal figure {
  width: 62px;
  height: 62px;
  border-radius: 100%;
  background: rgba(161, 167, 179, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
}

.purchase .payPal figure img {
  max-width: 90%;
}
/* .thanks {
  background: rgb(243, 246, 250);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 42%,
    #f3f6fa 42%,
    rgba(243, 246, 250, 1) 100%
  );
  min-height: calc(100vh - 72px);
  margin-top: 72px;
} */

@media (min-width: 700px) {
  .compra{
    margin-top: 72px;
    
  }
  .thanks {
    /* background: rgb(243, 246, 250); */
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 42%,
      #f3f6fa 42%,
      rgba(243, 246, 250, 1) 100%
    );
    min-height: calc(100vh - 72px);
  }
  .purchase {
    background: rgb(243, 246, 250);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 58%,
      #f3f6fa 58%,
      rgba(243, 246, 250, 1) 100%
    );
    min-height: calc(100vh - 72px);
    margin-top: 72px;
  }
  /* .purchase aside {
    background-color: #f3f6fa;
  } */
  .purchase h5 {
    color: #5a5e66;
    font-size: 0.625rem;
    letter-spacing: 0.078rem;
  }
  .purchase p {
    font-size: 0.875rem;
  }
}

@media (max-width: 700px) {
  .purchase section header .d-flex {
    flex-direction: column-reverse;
  }
  .purchase section header .d-flex p {
    width: 100%;
    margin-bottom: 1rem;
  }
}
